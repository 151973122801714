<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="联系电话" prop="mobile" disabled>-->
<!--        <a-input v-model="form.mobile" placeholder="请输入联系电话" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="注销原因" prop="reason" disabled>
        <a-input v-model="form.reason" placeholder="请输入注销原因" />
      </a-form-model-item>
      <a-form-model-item label="审核状态" prop="status" >
        <a-select placeholder="请选择审核状态" v-model="form.status">
          <a-select-option :value="2">同意</a-select-option>
          <a-select-option :value="3">拒绝</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="审核失败原因" prop="failReason" >
        <a-input v-model="form.failReason" placeholder="请输入审核失败原因" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCancel, addCancel, updateCancel } from '@/api/biz/cancel'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        mobile: null,
        reason: null,
        status: 0,
        failReason: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile:[
          { required: true, message: '联系电话不能为空' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写联系电话',
            trigger: 'blur'
          }
        ],
        reason: [
          { required: true, message: '注销原因不能为空' }
        ],
        status: [
          { required: true, message: '审核状态不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        reason: null,
        status: 0,
        failReason: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCancel({"id":id}).then(response => {
        this.form = response.data
        this.form.status=2
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCancel(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCancel(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
